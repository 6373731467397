.brand-row {
    width: 100%;
    margin: 0 auto;
    padding: 3% 0;
    font-family: 'Maison Neue';
}

.brand-row a {
    text-decoration: none;
    font-family: 'Maison Neue';
}

.brand-row p {
    font-size: 2em;
}

.brand-logo {
    width: 80%;
}

.features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.feature {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 5%;
    text-align: center;
    margin: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-title {
    font-size: 1.2em;
}

.feature-body {
    font-size: 1.2em;
    font-family: 'Maison Neue';
}

.circle-feature-title {
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    color: #fff;
}

.circle-feature-body {
    font-size: 1.4em;
    font-family: 'Maison Neue';
    color: #fff;
}

@media screen and (max-width: 1100px) {
    .feature {
        width: 300px;
        height: 300px;
    }
}


@media screen and (max-width: 600px) {
    .features {
        flex-direction: column;
    }

    .feature {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        border-radius: 50%;
        margin: 2% auto;
    }
}

.feature-image {
    width: 100%;
}

.each-slide > div {
    background-size: cover;
    height: 750px;
}

.project-inner-wrapper {
    position: absolute;
    bottom: 0;
    padding: 0.5%;
    min-width: 320px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
}

.project-inner-wrapper h2 {
    margin: 0 0 5px 0;
    padding: 0;
}

.project-inner-wrapper span {
    text-align: right;
    float: right;
}

.project-quick-facts {
    font-size: 1.4em;
    line-height: 1.5em;
}


@media screen and (max-width: 600px) {
    .each-slide > div {
        background-size: cover;
        height: 500px;
    }
}

