.header {
    color: #000;
    font-family: 'Maison Neue';
}

.header a {
    color: #000;
    text-decoration: none;
}

.header h3 {
    color: #000;
}

.toolbar-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-logo-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
}

.nav-subtext {
    flex-grow: 2;
}

.nav-subtext h3 {
    margin: 0;
    padding: 0;
}

.nav-logo {
    height: 100%;
}

.nav-buttons {
    width: 30%;
    float: right;
    text-align: right;
}

.header-span {
    font-size: 1.5em;
    margin-left: 20px;
    margin-right: 20px;
    vertical-align: 25%;
}

.nav-number {
    margin-left: auto;
    float: right;
    text-align: right;
    color: #000;
}

@media screen and (max-width: 600px) {
    .nav-logo-container {
        height: 20px;
    }

    .header-span {
        font-size: 1em;
        margin-left: 20px;
        margin-right: 20px;
        vertical-align: 40%;
    }

    .nav-number {
        display: none;
    }
}