.search-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

@media screen and (max-width: 1100px) {
    .search-container {
        flex-direction: column;
        width: 90%;
    }
}