footer { 
    margin: 2% 0 0 0;
}

footer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

footer li {
    padding: 1% 0;
}

.footer-main {
    padding: 3%;
    background-color: #C0C0C0;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-main a {
    color: #fff;
    text-decoration: none;
}

.footer-section {
    flex: 1;
    margin: 0 5%;
}

.footer-section-logo {
    flex: 1;
    margin: 0 5% 0 0;
}

.footer-logo {
    max-width: 300px; 
}

.copyright {
    padding: 1% 3%;
    background-color: #efefef;
    color: #000;
}

.copyright p {
    margin: 0;
}

.copyright a {
    color: #0373AF;
    text-decoration: none;
}


@media screen and (max-width: 600px) {
    .footer-main {
      flex-direction: column;
      align-items: baseline;
    }

    .footer-logo {
        max-width: 250px;
        margin: 0 0 0 0;
    }

    .footer-section {
        margin: 0 0 0 0;
    }

    .copyright {
        padding: 5% 8%;
    }
  }
