.project-wrapper {
    padding: 5% 1%;
    max-width: 100%;
}
.project-basics {
    text-align: center;
}

.project-basics a {
    text-decoration: none;
}

@media screen and (max-width: 1100px) {
    .project-wrapper {
        padding: 15% 3%;
    }
}

.unit-mix-slider {
    height: 300px;
    padding: 3%;
}

.proforma-wrapper {
    padding-top: 3%;
    width: 100%;
    overflow-x: scroll;
}

.basic-info {
    background-color: #fff;
    padding: 2%;
}

.basic-info a {
    text-decoration: none;
}

.project-cta-section {
    width: 100%;
    padding-top: 3%;
}

.project-info-wrapper {
    width: 100%;
    min-height: 100vh;
}

.map-div {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.project-left {
    position: absolute;
    width: 25%;
    min-height: 100vh;
    float: left;
    z-index: 5;
}

.project-right {
    width: 25%;
    min-height: 100vh;
    float: right;
}

.project-wrapper h2 {
    font-size: 1em;
}

.explainer img {
    width: 100%;
}

.explainer h1 {
    text-align: left;
}

.explainer p {
    text-align: left;
}

.project-info {
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    font-size: .8em;
}

.project-row {
    width: 85%;
    margin: 2% auto;
    padding: 3% 5%;
    font-family: 'Maison Neue';
}

.email-container {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: center;
    margin: 0 auto;
}

.email-field {
    flex-grow: 2;
}

@media screen and (max-width: 1100px) {
    
.email-container {
    width: 100%;
    flex-direction: column;
}

    .email-field {
        flex-grow: 1;
    }
}

.explainer img {
    width: 65%;
    margin: 0 auto;
}

@media screen and (max-width: 900px) {
    
    .explainer img {
        width: 100%;
    }
}

.packet-wrapper {
    padding: 5%;
    text-align: center;
}

.packet {
    padding-top: 2%;
    width: 50%;
    margin: 0 auto;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}