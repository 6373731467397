.faq-wrapper {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    gap: 25px;
}

.left {
    flex-grow: 1;
}

.right {
    flex-grow: 1;
}