@font-face{
    src: url('./fonts/MaisonNeue-Book.ttf') format('truetype');
    font-family: Maison Neue;
}

html {
  scroll-behavior: smooth;
  font-family: 'Maison Neue';
  background-color: #f9f8f2;
}

h1 {
  font-family: 'Maison Neue';
}
h2 {
  font-family: 'Maison Neue';
}
h3 {
  font-family: 'Maison Neue';
}
h4 {
  font-family: 'Maison Neue';
}
h5 {
  font-family: 'Maison Neue';
}
h6 {
  font-family: 'Maison Neue';
}
p {
  font-family: 'Maison Neue';
}

.app-wrapper {
  padding: 0;
  margin: 0;
  font-family: 'Maison Neue';
}

.container {
  margin: 0;
}

.basic-container {
  padding: 5%;
}

.fourohfour-container {
  padding: 5%;
  text-align: center;
}

.fourohfour-container a {
  color: #000;
}

.home-icon-container {
  margin-top: 5%;
  padding: 5%;
}

.home-icon {
  transform: scale(8);
}

.loading-spinner {
  width: 20%;
  margin: 5% auto;
  text-align: center;
  float: middle;
}

.splash-container {
  width: 100%;
  min-height: 100vh;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.splash-image {
  height: 100vh;
  width: 50%;
  float: right;
}

.splash-image img {
  height: 100vh;
  width: 100%;
}

@media screen and (max-width: 1100px) {
  .splash-container {
    flex-direction: column;
  }
  .splash-image {
    width: 100%;
    height: auto;
    padding-bottom: 2%;
  }
  .splash-image img {
    width: 100%;
    height: auto;
  }
}

.splash-text-container {
  float: left;
  margin: 0;
  text-align: center;
  font-size: 1.3em;
  width: 50%;
}

.splash-text-inner-wrapper {
  padding: 0px 8% 0px 8%;
}

@media screen and (max-width: 1100px) {
  .splash-text-container {
    padding: 80px 5% 0 5%;
    width: 90%;
  }
  .splash-text-inner-wrapper {
    padding: 0px 5% 0px 5%;
  }
}

.splash-text-container h1 {
  margin-top: 0;
}

.row {
  width: 85%;
  margin: 0 auto;
  padding: 5% 5%;
  font-family: 'Maison Neue';
}

.row-wide {
  width: 85%;
  margin: 0 auto;
  padding: 5% 5%;
  font-family: 'Maison Neue';
}

.row-last {
  width: 60%;
  margin: 0 auto;
  padding: 5% 5% 0 5%;
  font-family: 'Maison Neue';
}

.row-last a {
  color: #000;
  text-decoration: none;
}

.row h2 {
  font-size: 2em;
  font-family: 'Maison Neue';
}

@media screen and (max-width: 600px) {
  .row {
    width: 90%;
  }
  
  .row-wide {
    width: 90%;
  }
  
  .row-last {
    width: 90%;
  }

  .row h2 {
    font-size: 1.6em;
    font-family: 'Maison Neue';
  }
}

.centered {
  text-align: center
}

.half {
  font-size: 1.4em;
}

.two {
  font-size: 2em;
}

.feature-title {
  color: #3535c9;
}

.nodecoration {
  text-decoration: none;
}